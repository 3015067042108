import React from 'react';
import { Link, navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import { Form, TextField, createValidator, mustBeEmail, notEmpty, passwordPolicy, mustMatch } from 'components/form';
import { WaitButton } from '../WaitButton';
import { AuthPage } from './AuthPage';
import lost_password from './lost_password.png';
import { selectAuth } from 'app';
import { resetPassword } from 'app/authSlice';

const useStyles = makeStyles((theme) =>
  createStyles({
    field: {
      margin: theme.spacing(2, 0),
    },
    heading: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

type FormData = {
  email: string;
};

const initial: FormData = {
  email: '',
};

const validate = createValidator<FormData>({
  email: [notEmpty('Email can not be empty'), mustBeEmail('Invalid email address')],
});

export function ResetPassword() {
  const dispatch = useDispatch();
  const { status } = useSelector(selectAuth);
  const classes = useStyles();
  const handleSumbit = (data: FormData) => {
    dispatch(
      resetPassword(data.email, () => {
        navigate('/');
      }),
    );
  };
  return (
    <AuthPage img={<img src={lost_password} />} headercolor={blue[300]} bgcolor={blue[600]}>
      <Box p={4} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight={380}>
        <Typography variant="h6" className={classes.heading}>
          RESET PASSWORD
        </Typography>

        <Form<FormData> initial={initial} onSubmit={handleSumbit} validate={validate}>
          <TextField
            name="email"
            className={classes.field}
            variant="outlined"
            label="Email address"
            fullWidth
            required
          />

          <Box mt={2}>
            <WaitButton wait={status?.type === 'request'} variant="contained" color="primary" type="submit" fullWidth>
              Submit
            </WaitButton>
          </Box>

          <Box mt={2}>
            <Typography color="textSecondary" variant="body2" align="center">
            Once you have clicked submit, you will receive an email with instructions on how to reset your password. If you have a password, please <Link to="/auth/signin">Sign in</Link> instead.
            </Typography>
          </Box>
        </Form>
      </Box>
    </AuthPage>
  );
}
